import gjs from "grapesjs";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";

import scaleCrmForm from "./scaleCrmForm";
import scaleleadForm from "./scaleleadForm";
import scaleleadFormOpen from "./scaleleadFormOpen";
import cupidcoastForm from "./cupidcoastForm";
import createTable from "./createTable";
import redirectToLander from "./redirectToLander";
import customCode from "./customCode";
import swiperSlider from "./swiperSlider";
import popUpSlider from "./popUpSlider";
import header from "./headers/header";
import footer from "./footers/footer";
import sidebar from "./sidebars/sidebar";
import comment from "./comment";
import commentFbTemp1 from "./comment-fb-temp-1";
import commentFbTemp2 from "./comment-fb-temp-2";
import commentFbTemp3 from "./comment-fb-temp-3";
import commentNativeTemp1 from "./comment-native-temp-1";
import commentNativeTemp2 from "./comment-native-temp-2";
import commentNativeTemp3 from "./comment-native-temp-3";
import commentLayout2 from "./comment2";
import commentLayout3 from "./comment3";
import commentLayout4 from "./comment4";
import commentLayout5 from "./comment5";
import commentLayout6 from "./comment6";
import commentLayout7 from "./comment7";
import commentWrap from "./commentWrap";
import leaveComment from "./leaveComment";
import recensionWrap from "./recensionWrap";
import recension from "./recension";
import recensionLayout2 from "./recension2";
import recensionLayout3 from "./recension3";
import timer from "./timer";
import randomNumber from "./randomNumber";
import customButton from "./customButton";
import ctaButton from "./ctaButton";
import grid3x3 from "./grid3x3/grid3x3";
import customDate from "./customDate"
import unorderedList from "./unorderedList";
import articleBox from "./articleBox";
import headerTwo from "./headers/headerTwo";
import footerTwo from "./footers/footerTwo";
import template from "./templates/template";
import sidebarTwo from "./sidebars/sidebarTwo";
import templateTwo from "./templates/templateTwo";
import customBox from "./customBox";
import reviewBox from "./reviewBox";
import statementBox from "./statementBox";
import headerThree from "./headers/headerThree";
import footerThree from "./footers/footerThree";
import templateThree from "./templates/templateThree";
import headerFour from "./headers/headerFour";
import contactForm from "./contactForm";
import headerFive from "./headers/headerFive";
import footerFour from "./footers/footerFour";
import templateFour from "./templates/templateFour";
import templateFive from "./templates/templateFive";
import smallBoxes from "./smallBoxes";
import socialNetworks from "./socialNetworks";
import headerSix from "./headers/headerSix";
import sidebarThree from "./sidebars/sidebarThree";
import templateSix from "./templates/templateSix";
import mobileTemplate from "./templates/mobileTemplate";
import instaTemplate from "./templates/instaTemplate";
import viberTemplate from "./templates/viberTemplate";
import wappTemplate from "./templates/wappTemplate";
import messageComponent from "./templates/messageTemplate";
import cupidProfileTemplate from "./templates/cupidProfileTemplate";
import quizzie from "./templates/Quizzie";
import spinner from "./spinner";
import gatewayBox from "./gatewayBox";
import closedFormBoxes from "./closedFormBoxes";
import safeFormBoxes from "./safeFormBoxes";
import safeFormChest from "./safeFormChest";
import safeFormDoors from "./safeFormDoors";
import safeFormPotent from "./safeFormPotent";
import safeFormWheel from "./safeFormWheel";
import gatewayWheel from "./gatewayWheel";
import closedFormWheel from "./closedFormWheel";
import gatewayMedical from "./gatewayMedical";
import closedFormMedical from "./closedFormMedical";
import closedFormChest from "./closedFormChest";
import gatewayPotent from "./gatewayPotent";
import closedFormPotent from "./closedFormPotent";
import gatewayDoors from "./gatewayDoors";
import closedFormDoors from "./closedFormDoors";
import scrollToFormButton from "./scrollToFormButton";
import productBoughtPopUp from "./productBoughtPopUp";
import openForm from "./OpenForm";
import openForm2 from "./OpenForm2";
import openForm3 from "./OpenForm3";
import openForm4 from "./OpenForm4";
import openForm5 from "./OpenForm5";
import openForm6 from "./OpenForm6";
import openForm7 from "./OpenForm7";
import openFormFb1 from "./OpenForm-fb-1";
import openFormFb2 from "./OpenForm-fb-2";
import openFormFb3 from "./OpenForm-fb-3";
import bottomBarCta from "./bottomBarCta";
import genericTimer from "./GenericTimer";
import genericTimer2 from "./GenericTimer2";
import genericTimer3 from "./GenericTimer3";
import genericTimer4 from "./GenericTimer4";
import genericTimer5 from "./GenericTimer5";
import genericTimer6 from "./GenericTimer6";
import genericTimer7 from "./GenericTimer7";
import genericTimer8 from "./GenericTimer8";
import StyledTimer from "./StyledTimer";
import horizontalLine from "./horizontalLine";
import redirectOnParam from "./redirectOnParam";
import quizzieLayout from "./quizzieLayout";

import closedForms_weightLoss from "./closedFormWithSurvey/weightLoss";

import rteAddons from "./rteAddons";

import basicBlocks from "./basicBlocks";

/**
 * @param {gjs.Editor} editor
 */
export default async () => {

    //get offers here
    let data = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/integrations/getOffers`,
        data: {
            limit: null
        },
        ...backendModule.axiosConfig
    }).then(res => res.data).catch(() => []);
    if (data.data.status === "ok") data = data.data.data; else data = [];

    let trackData = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/campaigns/getAllOffers`,
        data: {
            limit: null
        },
        ...backendModule.axiosConfig
    }).then(res => res.data).catch(() => []);

    if (trackData.status === "ok"){
        trackData = trackData.data;
    } else {
        trackData = []
    };

    return (editor) => {
        customButton(editor);
        ctaButton(editor)
        basicBlocks(editor);

        scaleCrmForm(editor);
        scaleleadForm(editor, data);
        scaleleadFormOpen(editor, data)
        cupidcoastForm(editor, data);
        createTable(editor);
        redirectToLander(editor);
        rteAddons(editor);
        customCode(editor);
        swiperSlider(editor);
        popUpSlider(editor);
        header(editor);
        footer(editor);
        sidebar(editor);
        bottomBarCta(editor);
        comment(editor);
        commentFbTemp1(editor);
        commentFbTemp2(editor);
        commentFbTemp3(editor);
        commentNativeTemp1(editor);
        commentNativeTemp2(editor);
        commentNativeTemp3(editor);
        commentLayout2(editor);
        commentLayout3(editor);
        commentLayout4(editor);
        commentLayout5(editor);
        commentLayout6(editor);
        commentLayout7(editor);
        commentWrap(editor);
        leaveComment(editor);
        recensionWrap(editor);
        recension(editor);
        recensionLayout2(editor);
        recensionLayout3(editor);
        timer(editor);
        randomNumber(editor);
        grid3x3(editor);
        customDate(editor);
        unorderedList(editor);
        articleBox(editor);
        headerTwo(editor);
        footerTwo(editor);
        template(editor);
        sidebarTwo(editor);
        templateTwo(editor);
        customBox(editor);
        reviewBox(editor);
        statementBox(editor);
        headerThree(editor);
        footerThree(editor);
        templateThree(editor);
        headerFour(editor);
        contactForm(editor);
        headerFive(editor);
        footerFour(editor);
        templateFour(editor);
        templateFive(editor);
        smallBoxes(editor);
        socialNetworks(editor);
        headerSix(editor);
        sidebarThree(editor);
        templateSix(editor);
        mobileTemplate(editor);
        instaTemplate(editor);
        cupidProfileTemplate(editor);
        viberTemplate(editor);
        messageComponent(editor);
        wappTemplate(editor);
        quizzie(editor);
        spinner(editor);
        gatewayBox(editor);
        closedFormBoxes(editor, data, trackData);
        // gatewayWheel(editor);
        closedFormWheel(editor, data, trackData);
        // gatewayMedical(editor, data);
        closedFormMedical(editor, data, trackData);
        closedFormChest(editor, data, trackData);
        // gatewayPotent(editor);
        closedFormPotent(editor, data, trackData);
        // gatewayDoors(editor);
        closedFormDoors(editor, data, trackData);
        safeFormBoxes(editor, data, trackData);
        safeFormChest(editor, data, trackData);
        safeFormDoors(editor, data, trackData);
        safeFormPotent(editor, data, trackData);
        safeFormWheel(editor, data, trackData);
        openForm(editor, data, trackData);
        openForm2(editor, data, trackData);
        openForm3(editor, data, trackData);
        openForm4(editor, data, trackData);
        openForm5(editor, data, trackData);
        openForm6(editor, data, trackData);
        openForm7(editor, data, trackData);
        openFormFb1(editor, data, trackData);
        openFormFb2(editor, data, trackData);
        openFormFb3(editor, data, trackData);
        genericTimer(editor);
        genericTimer2(editor);
        genericTimer3(editor);
        genericTimer4(editor);
        genericTimer5(editor);
        genericTimer6(editor);
        genericTimer7(editor);
        genericTimer8(editor);
        StyledTimer(editor);
        scrollToFormButton(editor);
        productBoughtPopUp(editor);
        horizontalLine(editor);
        redirectOnParam(editor);
        quizzieLayout(editor);

        closedForms_weightLoss(editor, data, trackData);
    }
};